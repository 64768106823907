import React from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import CloseIcon from '@material-ui/icons/Close';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import Countdown from 'react-countdown';

const styles = {
  appBar: {
    position: 'relative',
  },
  left: {
    padding: '10px'
  },
  right: {
    padding: '10px',
    fontSize: '5rem',
    textAlign: 'center'
  },
  answer: {
    width:  '100%',
    textAlign: 'center'
  },
  answerBtn: {
    margin: '0 auto'
  },
  none: {},
  yellow: {
    backgroundColor: 'yellow'
  },
  orange: {
    backgroundColor: 'orange'
  },
  red: {
    backgroundColor: 'red'
  },
  center_img: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}

const successAudio = new Audio('./wohoo.mp3')
const wrongAudio = new Audio('./aw.mp3')

class Modal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showAnswer: false,
      bkColor: 'none',
      selectedAnswer: "",
      showCongrat: false,
      showWrong: false,
      answer: this.props.q.answer
    }
  }
  render() {
    const { open, q } = this.props

    return (
      <Dialog fullScreen open={open}>
        {this.createAppBar()}
        {this.createQuestion(q)}
      </Dialog>
    )
  }

  createAppBar = () => {
    const { classes, handleClose } = this.props

    return (
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    )
  }

  createQuestion = (q) => {
    if (!q.question || !q.answer) return ""
    const { classes, countdownTime = 10000 } = this.props

    const answers = q.choice ? _.map(q.choice, (a, idx) => {
      return (
        <ListItem key={idx} button
          selected={this.state.selectedAnswer === a}
          onClick={() => this.handleSelectAnswer(a)}
        >
          <ListItemIcon>
            <Chip
              variant="outlined"
              size="small"
              label={idx + 1}
            />
          </ListItemIcon>
          <ListItemText>{a}</ListItemText>
        </ListItem>
      )
    }) : (
      <TextField fullWidth={true}
        value={this.state.selectedAnswer}
        onChange={e => this.handleSelectAnswer(e.target.value)}
      />
    )

    return (
      <Grid container className={this.state.bkColor}>
        <Grid item xs={12} md={6}>
          <div className={classes.left}>
            <h1>{q.question}</h1>
            <List>
              {answers}
            </List>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={classes.right}>
            <Countdown
                date={Date.now() + countdownTime}
                intervalDelay={0}
                precision={5}
                renderer={props => <div>{props.total}</div>}
                onComplete={this.handleTimerComplete}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.answer}>
            <Button className={classes.answerBtn}
              variant="contained"
              color="primary"
              onClick={this.handleToogleAnswer}
            >
              Show Answer
            </Button>
            {this.state.showAnswer ? (<h2>{q.answer}</h2>) : ""}
            {this.state.showBomb ? (<div className={classes.center_img}><img alt="" src="./bomb_2.gif"/></div>) : ""}
            {this.state.showCongrat ? (<div className={classes.center_img}><img alt="" src="./congrat.gif"/></div>) : ""}
            {this.state.showWrong ? (<div className={classes.center_img}><img alt="" src="./next_time.gif"/></div>) : ""}
          </div>
        </Grid>
      </Grid>
    )
  }
  
  handleToogleAnswer = () => {
    const { showAnswer, selectedAnswer, answer} = this.state
    const isAnswerCorrect = selectedAnswer === answer
    const decision = isAnswerCorrect ? {showCongrat: true} : {showWrong: true}
    
    this.setState({
      showAnswer: !showAnswer,
      ...decision
    })

    if (!isAnswerCorrect) {
      this.playWrongSound()
    } else {
      this.playCorrectSound()
    }

    setTimeout(() => {
      this.setState({
        showCongrat: false,
        showWrong: false
      })
    }, 2000)
  }

  handleTimerComplete = () => {
    this.setState({
      showBomb: true
    })

    this.playWrongSound()

    setTimeout(() => {
      this.setState({
        showBomb: false
      })
    }, 1500)
  }

  handleSelectAnswer = (val) => {
    this.setState({
      selectedAnswer: val
    })
  }

  playCorrectSound = () => {
    
    successAudio.play()
  }

  playWrongSound = () => {
    wrongAudio.play()
  }
}

export default withStyles(styles)(Modal)