import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const styles = {
  card: {
    width: '150px',
    margin: '5px'
  },
  card_content: {
    position: "relative"
  },
  read: {
    backgroundColor: 'gray'
  },
  delete: {
    position: "absolute",
    top: 0,
    right: 0,
    color: 'lite gray'
  }
}

class _Card extends React.Component {
  render() {
    const { idx, classes, q, handleSelectQuestion, handleDelete } = this.props
    const { status } = q
    const readStyle = status === 'READ' ? classes.read : ""
    const cardStyle = classes.card + ' ' + readStyle

    return (
      <Card className={cardStyle}>
          <CardContent className={classes.card_content}>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              {`Q: ${idx}`}
            </Typography>
            <Typography className={classes.title} color="textPrimary" gutterBottom>
              {q.score + ':  ' + q.type}
            </Typography>
            <IconButton className={classes.delete} edge="start" onClick={handleDelete} aria-label="close">
              <CloseIcon />
            </IconButton>
          </CardContent>
          <CardActions>
            <Button onClick={() => handleSelectQuestion(q)}>Open</Button>
          </CardActions>
      </Card>
    )
  }
}

export default withStyles(styles)(_Card)