import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

const styles = {
  card: {
    width: '150px',
    margin: '5px'
  },
  card_content: {
    position: "relative"
  }
}

class _Card extends React.Component {
  render() {
    const { classes, handleChange} = this.props


    return (
      <Card className={classes.card}>
          <CardContent className={classes.card_content}>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Countdown Time
            </Typography>
            <TextField label={"second"} fullWidth={true}
              onChange={e => handleChange(e.target.value)}
            />
          </CardContent>
          <CardActions>
          </CardActions>
      </Card>
    )
  }
}

export default withStyles(styles)(_Card)