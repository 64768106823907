import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import CloseIcon from '@material-ui/icons/Close';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = {
  appBar: {
    position: 'relative',
  },
  form: {
    padding: '20px'
  },
  item: {
    padding: '10px'
  }
}

class AddQuestionModal extends React.Component {
  constructor(props) {
    super(props)

    const questionSet = this.props.currentQSet

    this.state = {
      showSuccessSnackbar: false,
      showErrorSnackbar: false,
      errorMsg: "",
      showAnswer: false,
      bkColor: 'none',
      form_questionSet: questionSet === "NEW" ? "" : questionSet,
      form_type: "",
      form_question: "",
      form_answer: "",
      form_choice1: "",
      form_choice2: "",
      form_choice3: "",
      form_choice4: "",
      form_score: "",
      form_status: "NEW"
    }
  }
  render() {
    const { open } = this.props

    return (
      <Dialog fullScreen open={open}>
        {this.createAppBar()}
        {this.createForm()}
        <Snackbar open={this.state.showSuccessSnackbar} autoHideDuration={1000} onClose={() => this.handleToogleSuccessSnackbar()}>
          <Alert severity="success">
            Data save successful
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.showErrorSnackbar} autoHideDuration={3000} onClose={() => this.handleToogleErrorSnackbar()}>
          <Alert severity="error">
            Error: {this.state.errorMsg}
          </Alert>
        </Snackbar>
      </Dialog>
    )
  }

  createAppBar = () => {
    const { classes } = this.props

    return (
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    )
  }
  
  createForm = () => {
    const { classes } = this.props
    return (
      <form className={classes.form} autoComplete="off">
        <Grid container>
          <Grid item xs={12} className={classes.item}>
            <Button variant="contained" color="secondary" onClick={() => this.handleClearForm()}>Clear</Button>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.item}>
            <TextField label="Question Set" fullWidth={true} required
              value={this.state.form_questionSet}
              onChange={val => this.handleFormValueChange('questionSet', val)}
            />
            <TextField label="Type" fullWidth={true}  required
              value={this.state.form_type}
              onChange={val => this.handleFormValueChange('type', val)}
            />
            <TextField label="Question" fullWidth={true} multiline rows={4}  required
              value={this.state.form_question}
              onChange={val => this.handleFormValueChange('question', val)}
            />
            <TextField label="Correct Answer" fullWidth={true}  required
              value={this.state.form_answer}
              onChange={val => this.handleFormValueChange('answer', val)}
            />
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Make sure Answer is exactly the same as one choice if any
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.item}>
            <Grid container>
              <Grid item xs={12}>
                <TextField label="Choice 1" fullWidth={true}
                  value={this.state.form_choice1}
                  onChange={val => this.handleFormValueChange('choice1', val)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Choice 2" fullWidth={true}
                  value={this.state.form_choice2}
                  onChange={val => this.handleFormValueChange('choice2', val)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Choice 3" fullWidth={true}
                  value={this.state.form_choice3}
                  onChange={val => this.handleFormValueChange('choice3', val)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Choice 4" fullWidth={true}
                  value={this.state.form_choice4}
                  onChange={val => this.handleFormValueChange('choice4', val)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.item}>
            <TextField label="Score" fullWidth={true} required
              value={this.state.form_score}
              onChange={val => this.handleFormValueChange('score', val)}
            />
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" type="submit"
              onClick={this.handleAddQuestion}
            >Submit</Button>
          </Grid>
        </Grid>
      </form>
    )
  }

  handleFormValueChange = (type, event) => {
    const { value } = event.target
    this.setState({
      [`form_${type}`]: value
    })
  }

  handleClearForm = (skip = []) => {
    const keys = Object.keys(this.state).filter(key => key.startsWith('form_'))
    const data = {}

    keys.forEach(key => {
      if (skip.indexOf(key) !== -1) return
      data[key] = ""
    })

    this.setState(data)
  }

  handleAddQuestion = e => {
    e.preventDefault()
    const keys = Object.keys(this.state).filter(key => key.startsWith('form_'))

    const data = {
      choice: []
    }

    keys.forEach(key => {
      const newKey = key.replace('form_', '')
      
      if (newKey.startsWith('choice') && this.state[key] === '') return
      

      if (newKey.startsWith('choice')) {
        data.choice.push(this.state[key])
      } else {
        data[newKey] = this.state[key]
      }
    })

    if (!this.validateData(data)) {
      this.handleToogleErrorSnackbar("data not correct")
      return
    }
    this.handleFirebaseAdd(data)
  }

  validateData = (data) => {
    if (Object.keys(data).length === 0) return false

    for (const key in data) {
      if (!data[key] || data[key] === '') return false
    }

    return true
  }

  handleFirebaseAdd = (data) => {
    const { form_questionSet } = this.state

    window._FIRE_DB.ref(`questionSet/${form_questionSet}`).set(true)
    const question = window._FIRE_DB.ref(`questions/${form_questionSet}`).push()
    question.set(data, err => {
      if (err) {
        console.log("saveError: ", err)
        this.handleToogleErrorSnackbar("error saving data")
      } else {
        this.handleClearForm(['form_type', 'form_questionSet', 'form_status'])
        this.handleToogleSuccessSnackbar()
      }
    })
  }

  handleToogleSuccessSnackbar = () => {
    const { showSuccessSnackbar } = this.state

    this.setState({
      showSuccessSnackbar: !showSuccessSnackbar
    })
  }

  handleToogleErrorSnackbar = (msg) => {
    const { showErrorSnackbar } = this.state

    this.setState({
      showErrorSnackbar: !showErrorSnackbar,
      errorMsg: msg
    })
  }

  handleClose = () => {
    const { currentQSet, handleClose} = this.props

    if (currentQSet === 'NEW') window.location.reload()

    handleClose()
  }
}

export default withStyles(styles)(AddQuestionModal)