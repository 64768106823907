import React from 'react'
import firebase from 'firebase'

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import { Button } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import Card from './component/Card'
import CardInput from './component/CardInput'
import Modal from './component/Modal'
import AddQuestionModal from './component/AddQuestionModal'

const firebaseConfig = {
  apiKey: "AIzaSyBFLGAjObLDIR9iBCSeneIVGj4sk0Vae2s",
  authDomain: "womucon-1556982708236.firebaseapp.com",
  databaseURL: "https://womucon-1556982708236-default-rtdb.firebaseio.com",
  projectId: "womucon-1556982708236",
  storageBucket: "womucon-1556982708236.appspot.com",
  messagingSenderId: "539774059880",
  appId: "1:539774059880:web:97f53b9ad632f7dc2642dd"
};
window._FIRE_DB = firebase.initializeApp(firebaseConfig).database()

const styles = {
  selectForm: {
    width: '200px',
    margin: '10px'
  },
  addQ: {
    margin: '10px'
  },
  reload: {
    margin: '20px',
    float: 'right'
  }
}

class App extends React.Component {
  constructor() {
    super()

    this.state = {
      questionSet: [],
      selectedQSet: "",
      questions: [],
      openAddQModal: false,
      openQuestionModal: false,
      currentQ: {},
      countdownTime: undefined
    }
  }

  componentDidMount() {
    this.getQuestionSet()
  }

  render() {
    const { classes } = this.props
    const QuestionModal = this.state.openQuestionModal ?
      <Modal open={this.state.openQuestionModal} handleClose={this.handleClearQuestion} q={this.state.currentQ} countdownTime={this.state.countdownTime}/> : ""

    const AddQModal = this.state.openAddQModal ? 
      <AddQuestionModal open={this.state.openAddQModal} currentQSet={this.state.selectedQSet} handleClose={this.handleToggleAddQuestion}/> : ""
    return (
      <div>
        <Grid container>
          <Grid item xs={12}>
            <FormControl className={classes.selectForm}>
              <InputLabel id="demo-simple-select-label">Select Question Set</InputLabel>
              <Select
                defaultValue=""
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.selectedQSet}
                onChange={this.handleSelectionQSet}
              >
                <MenuItem key={"createNew"} value={"NEW"}>Create New</MenuItem>
                {this.state.questionSet.map((val, idx)=> (<MenuItem key={idx} value={val}>{val}</MenuItem>))}
              </Select>
            </FormControl>
            <Button variant="contained" className={classes.reload} color='primary' onClick={this.handleReloadQuestions}>Reload</Button>
          </Grid>
          <CardInput key={'time-allow'} handleChange={this.handleChangeTime}/>
          { this.createCardList() }
          <Grid item xs={12}>
            <div className={classes.addQ}>
              <Button variant="contained" color="primary" onClick={this.handleToggleAddQuestion}>Add Question</Button>
            </div>
          </Grid>
        </Grid>
        {QuestionModal}
        {AddQModal}
      </div>
    )
  }

  createCardList = () => {
    const CardList = []
    let idx = 1

    for (const key in this.state.questions) {
      const q = this.state.questions[key]
      CardList.push(
        <Card key={key} idx={idx} q={q}
          handleDelete={() => this.handleDeleteQuestion(q, key)}
          handleSelectQuestion={() => this.handleSelectQuestion(q, key)}
        />
      )
      idx++
    }

    return CardList
  }

  handleChangeTime = (val) => {
    this.setState({
      countdownTime: Number(val) * 1000
    })
  }

  handleSelectQuestion = (q, key) => {
    const { questions } = this.state
    questions[key].status = "READ"
    this.setState({
      openQuestionModal: true,
      currentQ: q,
      questions
    })
  }

  handleClearQuestion = () => {
    this.setState({
      openQuestionModal: false,
      currentQ: {}
    })
  }

  handleToggleAddQuestion = () => {
    const { openAddQModal } = this.state
    this.setState({
      openAddQModal: !openAddQModal,
      openQuestionModal: false
    })
  }

  handleSelectionQSet = e => {
    this.setState({
      selectedQSet: e.target.value
    })

    this.getQuestions(e.target.value)
  }

  handleSelectQuestions = (questions) => {
    this.setState({
      questions
    })
  }

  handleReloadQuestions = () => {
    window.location.reload()
  }

  handleDeleteQuestion = (q, key) => {
    const { selectedQSet } = this.state
    
    if (selectedQSet === "" || !key) return

    const sureDelete = window.prompt("input 'g' to confirm delete")

    if (sureDelete !== "g") return
    
    this.deleteQuestion(selectedQSet, key)
  }

  getQuestionSet = () => {
    window._FIRE_DB.ref('questionSet').get().then(snapshot => {
      this.setState({
        questionSet: snapshot.val() ? Object.keys(snapshot.val()) : []
      })
    })
  }

  getQuestions = (qSet) => {
    window._FIRE_DB.ref(`questions/${qSet}`).get().then(snapshot => {
      this.handleSelectQuestions(snapshot.val())
    })
  }

  deleteQuestion = (qSet, path) => {
    window._FIRE_DB.ref(`questions/${qSet}/${path}`).set(null, err => {
      if (err) {
        console.log(err)
      }

      this.getQuestions(qSet)
    })
  }
}

export default withStyles(styles)(App);
